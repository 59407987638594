.description-wrapper {
    p:not(:first-child) {
        margin-bottom: theme('margin.productTabInfo');
    }
    p,
    span {
        line-height: theme('lineHeight[6]');
        letter-spacing: theme('letterSpacing.wide');
    }
}

.description-content {
    text-overflow: ellipsis;
    line-height: theme('lineHeight[6]');

    img {
        height: theme('height.badgeDescriptionImg') !important;
        width: theme('width.badgeDescriptionImg') !important;
    }
}
