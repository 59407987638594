/* This module allow you to extend the clickable area of an element ( .element ) by
   creating a clickable transparent layer that covers the entire container ( .container ) */

/* "container" : class to add to the element you want to cover */
/* "element" : class to add to the clickable element you want to extend the clickable area from */

/* exemple:
    import fullClickable from '../fullClickable.module.css';

    <div class="fullClickable.container">
        <img src="image.jpg"/>
        <button class="fullClickable.element">Click Me</button>
    </div>

    In this exemple the clickable area will also cover the image without the need to wrap
    everything in the button or duplicate it
*/

/*
* WARNING : if there is other clickable elements on the container you may need to but a z-index
* on them to make sure they are not bellow the layer
*/

.container {
    position: relative;
}

.container .element::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0);
    cursor: pointer;
}
