@media screen and (min-width: theme('screens.desktopS')) {
    .hover-container:hover + .hover-banner-background {
        visibility: visible;
    }

    .hover-container:hover .hover-item * {
        text-decoration: underline;
    }

    .hover-container:hover .hover-item svg {
        stroke: var(--text-primary);
        stroke-width: 0.01rem;
    }
}

.hover-banner-background {
    top: theme('height.headerFixedTablet');
    height: 1000vh;

    @media screen and (min-width: theme('screens.desktop')) {
        top: theme('height.headerDesktop');
    }
}

.hover-banner-arrow {
    transform: rotate(-135deg) translate(0.5rem);
    border: 2px solid var(--border-brand);
    border-top: 0;
    border-left: 0;
    border-radius: 0;
}
