.star {
    --percent: calc(var(--fillRatio) * 100%);

    &::before {
        content: '★';
        background: linear-gradient(
                90deg,
                var(--surface-brand) var(--percent),
                var(--background-form) var(--percent)
        );
        background-clip: text;
        -webkit-text-stroke: 1px var(--surface-brand);
        /* text-stroke is the definitive version of -webkit-text-stroke, do not remove to allow full support of
        * all browser when they will implement this feature.
        * -webkit-text-stroke should be removed when text-stroke will be fully supported by all browsers.
        text-stroke: 1px var(--surface-brand);
        */
        -webkit-text-fill-color: transparent;
    }
}


